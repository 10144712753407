<template>
  <b-modal id="dialog_add_location_group" size="lg">
    <template #modal-title>
      <h3> กลุ่มพื้นที่</h3>
    </template>

    <div>
      <div class="row">
        <div class="col-sm-6 mb-4"></div>
        <div class="col-sm-6">
          <b-input-group >
            <b-input-group-append>
              <b-input-group-text  class="input_border_radius_left_top_bt">
                <b-icon icon="search" />
              </b-input-group-text>
            </b-input-group-append>
            <b-form-input placeholder="ระบุการค้นหา เช่น ชื่อกลุ่มสถานที่ตั้ง"
                          v-model="criteria.search" class="form-control-group" @keyup.enter.native="searchHandler()">
            </b-form-input>
            <b-input-group-append>
              <b-button text="Button" variant="primary" @click="searchHandler()" style="width: 86px;">
                ค้นหา
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="table-responsive padding_top_20">
        <table class="table align-middle table-bordered">
          <thead>
            <tr class="text-center" >
              <th style="min-width: 50px; width: 1px;">เลือก</th>
              <th @click="sortHandler('name')">ชื่อกลุ่มสถานที่ตั้ง
                <div class="float-right" v-if="criteria.orderBy=='name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in items" :key="`list-supplier-mapping-location-select-${index}-${data.id}`"
                :id="`checkbox_location_group${index}`">
              <td class="text-center checkbox_style" >
                <b-form-checkbox :name="`checkbox_group${data.id}`" v-model="data.selected" @change="selecteLocationGroup(...arguments, data, index)" />
              </td>
              <td>{{data.name}}</td>
            </tr>
            <tr v-if="items.length === 0" class="text-center">
              <td colspan="2">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row padding_top_20">
        <div class="col-lg-2 col-md-2 col-3 page_m_auto">
          <select class="form-control form-control-sm"
                  id="pageLimit"
                  style="width: 80px;"
                  v-model="paging.limit" v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages"
                    :key="data"
                    :value="data">
              {{data}}
            </option>
          </select>
        </div>
        <div class="col-lg-10 col-md-12 col-sm-10">
          <div class="text-right paging_footer" v-if="paging.rowTotal > 0">
            <span class="display-total-paging"
                  style="margin: auto; font-size: 12px;
                  font-family: Sarabun-Light;
                  margin-right: 12px;">แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ</strong></span>
            <paginate v-model="paging.page"
                      :first-last-button="true"
                      :page-count="paging.pageTotal"
                      :page-range="5"
                      :margin-pages="2"
                      :prev-text="'&lt;'"
                      :next-text="'&gt;'"
                      :first-button-text="'&verbar;&lt;'"
                      :last-button-text="'&gt;&verbar;'"
                      :click-handler="changePage"
                      :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                      :page-class="'page-link'"
                      :prev-class="'page-link prev_paging'"
                      :next-class="'page-link next_paging'">
            </paginate>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="primary" @click="selectedHandler()" :disabled="disabledSelectedHandler()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
      <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
import Config from '../../../util/config';
import $ from 'jquery';
  export default {
    name: 'supplier_mapping_location_add_group',
    data() {
      return {
        criteria: {
          search: "",
          orderBy: "",
          ascDesc: ""
        },
        items: [],
        paging: {
          page: 1,
          limit: Config.pagination.limit,
          pages: Config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0
        },
        selected: []
      }
    },
    methods: {
      selectedHandler () {
        this.$emit('selected', this.selected);
        this.$bvModal.hide('dialog_add_location_group');
      },
      close () {
        this.$bvModal.hide('dialog_add_location_group');
      },

      changeLimit(){
        this.getListItems();
      },
      changePage (page) {
        this.paging.page = page;
        this.getListItems();
      },
      sortHandler(orderBy){
        if(orderBy!=this.criteria.orderBy){
          this.criteria.ascDesc = "asc";
        }else{
          this.criteria.ascDesc = this.criteria.ascDesc=="asc"?"desc":"asc";
        }
        this.criteria.orderBy = orderBy;
        this.getListItems();
      },
      searchHandler(){
        this.paging.page = 1;
        this.getListItems();
      },
      async getListItems () {
        let params = `search=${this.criteria.search}`;
        params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
        const result = await this.HttpServices.getData(`/ab_location_group?${params}`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          var total = Math.ceil(data.rowTotal/this.paging.limit);
          this.paging.rowTotal = data.rowTotal;
          this.paging.pageTotal = total;
          this.items = data.data;
        }else{
          this.paging.rowTotal = 0;
          this.items = [];
        }
      },
      disabledSelectedHandler(){
        let disabled = true;
        for(const v of this.items){
          if(v.selected){
            disabled = false;
            break;
          }
        }
        return disabled;
      },
      selecteLocationGroup (value, obj, indx) {
        if (value) {
          this.setSelectedColumn(obj);
          $(`tr#checkbox_location_group${indx}`).attr('class', 'highlight');
        } else {
          const index = this.selected.indexOf(obj);
          if (index > -1) {
            this.selected.splice(index, 1);
          }
          $(`tr#checkbox_location_group${indx}`).removeClass('highlight');
        }
      },
      setSelectedColumn (obj) {
        obj.group_type = 'location_group';
        this.selected.push(obj);
      }
    }
  }
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
