<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-plus"></i> เพิ่มข้อมูลกำหนดราคาจัดส่ง</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <form-supplier-mapping-location />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formSupplierMappingLocation from './form.supplier_mapping_location';
export default {
  name: 'supplier_mapping_location-view-page-supplier_mapping_location-add',
  methods: {},
  components: {
    formSupplierMappingLocation
  },
  async mounted() {}
}
</script>
