<template lang="">
  <div v-if="addDateDisplay">
    <h6 class="heading-small text-muted mb-4">ข้อมูลกำหนดราคาจัดส่ง</h6>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <b-form @submit.prevent="handleSubmit(addDateSaveHandler)">
        <div class="pl-lg-4">
          <div class="mb-2 row">
            <label class="col-sm-2 col-form-label label_algin_right">
              <strong>ชื่อซัพพลายเออร์ : </strong>
            </label>
            <label class="col-sm-10 col-form-label">{{item.name}}</label>
          </div>
          <div class="mb-2 row">
            <label class="col-sm-2 col-form-label label_algin_right">
              <strong>อีเมล : </strong>
            </label>
            <label class="col-sm-10 col-form-label">{{item.email}}</label>
          </div>
          <div class="mb-2 row">
            <label class="col-sm-2 col-form-label label_algin_right">
              <strong>วันเวลา : </strong>
            </label>
            <div class="col-sm-3 col-form-label">
              <date-picker :format="'DD/MM/YYYY HH:mm'" v-model="location.active" class="w-100" input-class="form-control"
                placeholder="วันเวลา" :append-to-body="true" type="datetime" :disabled-date="disabledStartDate" :disabled="addDateType=='view'"></date-picker>
              <base-input name="date_selected" v-model="date_selected" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
            </div>
          </div>
          <hr class="my-4">
          <b-button size="md" variant="primary" @click="onOpenLocationHandler" v-if="addDateType!='view'" style="width: 110px;"><i class="fa fa-plus"></i> เพิ่มพื้นที่</b-button>
          <b-button size="md" variant="primary" @click="onOpenLocationGroupHandler" v-if="addDateType!='view'" style="width: 130px;"><i class="fa fa-plus"></i> เพิ่มกลุ่มพื้นที่</b-button>
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th style="width: 30px;" v-if="addDateType!='view'">ลบ</th>
                  <th style="width: 250px;">ชื่อกลุ่มพื้นที่</th>
                  <th style="width: 350px;">ชื่อพื้นที่</th>
                  <th>ที่อยู่</th>
                  <th style="width: 250px;">ราคาจัดส่ง</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in location.locations" :key="`list-location-selected-${index}-${data.id}`">
                  <td class="text-center checkbox_style" v-if="addDateType!='view'">
                    <button type="button" class="btn btn-danger btn-sm" @click="onDeleteSelectHandler(data, index)">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </td>
                  <td>{{data.group_type=='location_group'?data.name:''}}</td>
                  <td>{{data.group_type=='location'?data.name:''}}</td>
                  <td>{{data.address}}</td>
                  <td>
                    <b-form-checkbox :name="`visible_${data.group_type}_${data.id}`" size="md" checked>ยกเลิกจัดส่ง</b-form-checkbox>
                    <base-input v-decimal-format v-model="data.cost" :name="`cost_${data.group_type}_${data.id}`" inputClasses="form-control-sm" :rules="{required: true}" :disabled="addDateType=='view'"></base-input>
                  </td>
                </tr>
                <tr v-if="location.locations.length === 0" class="text-center">
                  <td colspan="4">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr class="my-4">
        </div>
        <div :class="`float-right d-flex`" style="margin-top: 20px;">
          <span @click="addDateHandler()" class="text-underline pl-3 pr-3 nav-link"> กลับ</span>
          <!-- <b-button variant="secondary" @click="addDateHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
          <b-button type="submit" variant="primary" v-if="addDateType!='view'" class="style_btn"><i class="fa fa-check"></i> บันทึก</b-button>
        </div>
      </b-form>
    </validation-observer>
    <div>
      <dialog-add-location @selected="onSelectedLocationHandler" ref="dialogAddLocation"/>
      <dialog-add-location-group @selected="onSelectedLocationHandler" ref="dialogAddLocationGroup"/>
    </div>
  </div>
  <div v-else>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <b-form @submit.prevent="handleSubmit(saveHandler)">
        <div class="pl-lg-4">
          <div class="mb-2 row">
            <label class="col-sm-2 col-form-label label_algin_right">
              <strong>ชื่อซัพพลายเออร์ : </strong>
            </label>
            <label class="col-sm-10 col-form-label">{{item.name}}</label>
          </div>
          <div class="mb-2 row">
            <label class="col-sm-2 col-form-label label_algin_right">
              <strong>อีเมล : </strong>
            </label>
            <label class="col-sm-10 col-form-label">{{item.email}}</label>
          </div>
          <hr class="my-4">
          <b-button size="md" variant="primary" @click="addDateHandler()" class="style_btn" style="width: 100px;">
            <i class="fa fa-plus"></i> เพิ่มวันที่
          </b-button>
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th style="width: 30px;">ลบ</th>
                  <th>วันเวลา</th>
                  <th style="width: 180px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in mappings" :key="`mapping_location_add_date${index}`" :class="!data.flagEdit?'bg-light':''">
                  <td class="text-center checkbox_style" >
                    <button type="button" class="btn btn-danger btn-sm" @click="deleteDateHandler(data, index)" v-if="data.flagEdit">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </td>
                  <td>
                    {{toDate(data.active)}}
                    <!-- <date-picker :format="'DD/MM/YYYY HH:mm'" v-model="data.active" class="w-100" input-class="form-control"
                                  placeholder="วันเวลา" :append-to-body="true" type="datetime" :disabled="!data.flagEdit">
                    </date-picker> -->
                  </td>
                  <td class="text-center" >
                    <span class="style_icon_btn">
                      <i class="fas fa-pen" @click="editDateHandler(data, 'edit', index)" title="แก้ไข" v-if="data.flagEdit"></i>
                      <i class="fas fa-eye" @click="editDateHandler(data, 'view', index)" title="พรีวิว" v-else></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="mappings.length === 0" class="text-center">
                  <td colspan="4">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr class="my-4">
        </div>
        <div class="float-right d-flex" style="margin-top: 20px;">
          <span><router-link :to="'/supplier-mapping-location'" class="nav-link text-underline"> กลับ</router-link></span>
          <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
          <b-button type="submit" variant="primary" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import DateUtils from '../../../util/dateUtils';
import dialogAddLocation from './dialog.add.location';
import dialogAddLocationGroup from './dialog.add.location_group.vue';
export default {
  name: 'supplier_mapping_location-view-form-supplier_mapping_location',
  data () {
    return {
      permission:{
        appSlug: 'supplier-mapping-location',
        actionData: {}
      },
      addDateDisplay: false,
      addDateType: '',
      addDateIndex: 0,
      item: {},
      mappings: [],
      location: {
        active: null,
        locations: []
      },
      date_selected: '',
      deletes: []
    }
  },
  props: [],
  methods: {
    /************ Add Date ************/
    disabledStartDate(date){
      return false;//date < new Date(); //TODO open all date
    },
    addDateHandler(){
      this.addDateDisplay = !this.addDateDisplay;
      this.location = {
        active: null,
        locations: []
      };
      this.addDateType = "add";
    },
    addDateSaveHandler(){
      if(this.location.locations.length==0){
        this.AlertUtils.alert('warning', "กรุณาเพิ่มพื้นที่");
        return;
      }
      this.location.flagEdit = true;
      if(this.addDateType=="edit"||this.addDateType=="view"){
        this.mappings.splice(this.addDateIndex, 1, this.location);
      }else{
        this.mappings.push(this.location);
      }
      this.addDateDisplay = false;
    },
    editDateHandler(v, type, index){
      this.addDateType = type,
      this.addDateIndex = index;
      this.location = JSON.parse(JSON.stringify(v));
      this.location.active = new Date(this.location.active);
      this.addDateDisplay = true;
    },
    deleteDateHandler(v, index){
      if(v.locations&&v.locations.length>0){
        for(const vv of v.locations){
          if(vv.ml_id){
            this.deletes.push(vv.ml_id);
          }
        }
      }
      this.mappings.splice(index, 1);
    },
    /************ Add Date ************/

    /************ Location ************/
    onOpenLocationHandler(){
      this.$refs.dialogAddLocation.getListItems();
      this.$bvModal.show('dialog_add_location');
    },
    onOpenLocationGroupHandler(){
      this.$refs.dialogAddLocationGroup.getListItems();
      this.$bvModal.show('dialog_add_location_group');
    },
    onSelectedLocationHandler(s){
      const st = JSON.parse(JSON.stringify(this.location));
      for(const v of s){
        const storeId = v.id;
        let flagInsert = true;
        for(const vv of st.locations){
          if(vv.id==storeId&&v.group_type==vv.group_type){
            flagInsert = false;
          }
        }
        if(flagInsert){
          v.cost = '';
          const data = JSON.parse(JSON.stringify(v));
          this.location.locations.push(data);
        }
      }
    },
    onDeleteSelectHandler(v, index){
      if(v.ml_id){
        this.deletes.push(v.ml_id);
      }
      this.location.locations.splice(index, 1);
    },
    /************ Location ************/

    /************ Common ************/
    async saveHandler () {
      if(this.mappings.length==0){
        this.AlertUtils.alert('warning', "กรุณาเพิ่มวันที่");
        return;
      }
      const m = [];
      for(const v of this.mappings){
        const activeDate = DateUtils.dateFormat(v.active, "YYYY-MM-DD HH:mm:ss");
        const d = JSON.parse(JSON.stringify(v));
        d.active = activeDate;
        m.push(d);
      }
      const params = {mappings: JSON.stringify(m), deletes: JSON.stringify(this.deletes)};
      const result = await this.HttpServices.putData(`/ab_supplier_location/${this.item.id}`, params);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/supplier-mapping-location');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/supplier-mapping-location");
    },

    async getItem(){
      const result = await this.HttpServices.getData(`/ab_supplier/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data;
        const r = await this.HttpServices.getData(`/ab_supplier_location/${this.id}`);
        if(r&&r.status.code=="200"){
          const data = r.data;
          for(const v of data){
            // v.active = new Date(v.active);
            v.active = v.active.replaceAll("Z", "");
            v.active = DateUtils.toDateFormat(v.active, "YYYY-MM-DDTHH:mm:ss.SSS");
            v.flagEdit = DateUtils.afterDate(v.active, new Date());
            this.mappings.push(v);
          }
        }
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลซัพพลายเออร์`, ()=>{
          this.$router.push('/supplier-mapping-location');
        });
      }
    },
    toDate(date){
      if(!date){
        return '';
      }

      return DateUtils.dateFormat(date, "DD/MM/YYYY HH:mm:ss")
    }
    /************ Common ************/
  },
  components: {
    dialogAddLocation, dialogAddLocationGroup
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-supplier-mapping-location");
    await this.getItem();
  },
  watch:{
    'location.active'(newVal){
      if(newVal){
        this.date_selected = ""+newVal;
      }else{
        this.date_selected = "";
      }
    }
  }
}
</script>
